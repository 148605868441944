@use 'colors.scss' as *;


/*
$primary-lightest: #EEECFE;
$primary-light: #AC9EFA;
$primary: #6F57F7;
$primary-dark: #4729F5;
$primary-darkest: #030113;

$success: #88B7B5;

$danger: #EE4266;

$warning: #E58F28;

$lightest: #FFFFFF;
$medium: #8192B1;
$dark: #4A4A4A;
$darkest: #11151C; 
*/

:root {
    --background: #FFFFFF;
    --middleground: #b8b8b8;
    --foreground: rgb(246, 249, 252);
    --foreground-dark: #E5E5E5;
    --text: #11151C;
    --text-inverted: #FFFFFF;
    --text-medium: #3c3c3d;
}

[data-theme="dark"] {
    --background: #030113;
    --middleground: #4A4A4A;
    --foreground: #11151C;
    --foreground-dark: #0d1015;
    --text: #FFFFFF;
    --text-inverted: #11151C;
    --text-medium: #a4a4a9;
}

* {
    padding: 0;
    margin: 0;

    color: inherit;
    font-size: inherit;
}

body {
    font-family: Inter;
    font-size: 14pt;

    font-feature-settings: "rlig", "calt" 0;
    font-weight: 400;

    background: var(--background);
    background-repeat: no-repeat;

    height: 100vh;

    color: var(--text);
}

a {
    text-decoration: none;
}