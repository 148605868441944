@use 'vars.scss' as *;
@use 'colors.scss' as *;

.button {
    outline: 0;
    border: 1px solid $darkest;

    border-radius: $border-radius-small;

    padding: $spacing $spacing-large;

    cursor: pointer;

    display: flex;
    flex-direction: row;

    gap: $spacing;

    align-items: center;
    justify-content: space-between;

    transition: $transition;
}

.button-danger {
    background: transparent;
    border-color: $danger;

    color: $danger;
}

.button-danger:hover {
    background: rgba($danger, 0.2);
    border-color: $danger;
}

.button-big {
    padding: $spacing-large $spacing-huge;
}

.button-disabled {
    background: transparent;
    border-color: var(--text-inverted);

    color: var(--text-inverted);

    cursor: default;

    pointer-events: none;
}

.button-inverted {
    background: var(--text);
    border-color: var(--text);

    color: var(--text-inverted);
}

.button-inverted:hover {
    background: var(--middleground);
    border-color: var(--text-inverted);

    color: var(--text);
}

.button-default {
    background: transparent;
    border-color: $dark;

    color: var(--text);
}

.button-default:hover {
    border-color: --text;
}

.button-cta {
    background: transparent;
    border-color: $primary-dark;

    color: $primary-dark;
}

.button-cta:hover {
    color: $primary;
    border-color: $primary;
}

.button-cta {
    background: transparent;
    border-color: $primary-dark;

    color: $primary-dark;
}

.button-cta:hover {
    color: $primary;
    border-color: $primary;
}

.button-transparent {
    background: transparent;
    border-color: transparent;

    color: var(--middleground);
}

.button-transparent:hover {
    color: var(--text);
}

.button-cta {}