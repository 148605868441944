@use 'colors.scss' as *;
@use 'vars.scss' as *;

.payment-status {
	display: flex;
	flex-direction: column;

	gap: 20px;

	align-items: center;
	justify-content: center;

	height: calc(100vh - 140px);

	.fail-status-icon {
		color: $primary;
	}

	.success-status-icon {
		color: $success;
	}
}

.payment-request {
	display: flex;
	flex-direction: column;

	align-items: center;
	justify-content: center;

	height: calc(100vh - 140px);

	.payment-container {
		display: flex;
		flex-direction: column;

		align-items: center;

		gap: 20px;

		border: 1px solid var(--middleground);
		background: var(--text-inverted);
		border-radius: 10px;

		padding: 20px;

		.pricing-card {
			display: flex;
			flex-direction: column;

			justify-content: space-between;

			gap: $spacing-large;

			border-radius: $border-radius;
			border: 1px solid $darkest;

			padding: $spacing-huge;

			.card-content {
				display: flex;
				flex-direction: column;

				gap: $spacing;

				font-size: 18pt;


				.header {}

				.body {
					display: flex;
					flex-direction: column;

					gap: $spacing;

					.body-header {
						display: flex;
						flex-direction: row;

						gap: $spacing;

						align-items: center;

						.price {
							font-weight: 700;
						}

						.price-dark {
							color: $dark;
						}

						.price-label {
							font-size: 14pt;
							color: $dark;
						}
					}

					.features {
						display: flex;
						flex-direction: column;

						gap: $spacing-large;

						.feature {
							display: flex;
							flex-direction: row;

							gap: $spacing;

							align-items: center;

							.has-feature-icon {
								color: $primary;
							}

							.feature-text {
								color: $primary;
							}

							.no-feature-icon {
								color: $dark;
							}

							.no-feature-text {
								color: $dark;
							}
						}
					}
				}

			}
		}

		.digital-payments-container {
			display: flex;
			flex-direction: column;

			width: 100%;

			gap: 10px;
		}

		.payment-methods {
			display: flex;
			flex-direction: column;

			align-items: center;

			gap: 20px;
		}
	}
}