@use 'vars.scss' as *;
@use 'colors.scss' as *;

.profile {
    display: flex;
    flex-direction: column;

    gap: $spacing-huge * 2;

    align-items: center;
    justify-content: center;

    .profile-content {
        display: flex;
        flex-direction: column;

        gap: $spacing-huge;

        align-items: center;

        .avatar {
            position: relative;

            cursor: pointer;

            .avatar-image {
                border-radius: 50%;

                transition: $transition;
            }

            .edit-overlay {
                position: absolute;

                top: 50%;
                left: 50%;

                transform: translate(-50%, -50%);

                opacity: 0;

                transition: $transition;

                color: $lightest;
            }

            .user-tier {
                padding: $spacing-small $spacing;

                border-radius: $circular-border-radius;
                border: 4px solid var(--background);

                position: absolute;

                top: 0;
                left: 0;

                display: flex;
                flex-direction: row;

                gap: $spacing;

                align-items: center;

                z-index: 2;
            }

            .pro-tier {
                background: $primary;

                color: $lightest;

                transform: translate(25%, 25%);
            }

            .unlimited-tier {
                background: $warning;

                color: $lightest;

                transform: translate(0%, 25%);
            }
        }

        .avatar:hover {
            .edit-overlay {
                opacity: 1;
            }

            .avatar-image {
                filter: brightness(0.1);
            }
        }

        .profile-details {
            display: flex;
            flex-direction: column;

            gap: $spacing;

            align-items: center;

            .age {
                color: var(--middleground);
            }

            .name {
                font-size: 18pt;


            }
        }

        .more-label {
            color: var(--middleground);
            ;

            cursor: pointer;

            transition: $transition;
        }

        .more-label:hover {
            color: $primary;
        }

        .profile-personality {
            display: flex;
            flex-direction: row;

            gap: $spacing;

            align-items: center;

            .personality-trait {
                background: rgba($primary-dark, 0.2);

                border-radius: $circular-border-radius;
                border: 1px solid $primary;

                padding: $spacing $spacing-large;

                display: flex;
                flex-direction: row;

                gap: $spacing;

                align-items: center;

                .trait {}
            }
        }

        .profile-websites {
            display: flex;
            flex-direction: row;

            gap: $spacing;

            align-items: center;

            .website {
                background: rgba(var(--text), 0.2);

                border-radius: $circular-border-radius;
                border: 1px solid $dark;

                padding: $spacing $spacing-large;

                display: flex;
                flex-direction: row;

                gap: $spacing;

                align-items: center;

                cursor: pointer;

                transition: $transition;

                .trait {}
            }

            .website:hover {
                background: var(--text-inverted);
                // color: var(--text-inverted);
            }
        }

        .profile-bio {
            display: flex;
            flex-direction: column;

            gap: $spacing;

            align-items: center;

            max-width: 50%;

            text-align: center;

            .bio {
                color: var(--middleground);

                display: flex;
                flex-direction: row;

                gap: $spacing;

                align-items: center;

                outline: 0;

                .edit-icon {
                    color: var(--middleground);

                    cursor: pointer;

                    transition: $transition;
                }

                .edit-icon:hover {
                    color: var(--text);
                }
            }
        }

        .profile-conversations {
            display: flex;
            flex-direction: row;

            gap: $spacing-large;

            align-items: center;

            .conversation {
                display: flex;
                flex-direction: column;

                gap: $spacing;

                align-items: center;

                border: 1px solid var(--middleground);
                border-radius: $border-radius;

                padding: $spacing;

                .conversation-header {
                    .name {
                        color: var(--middleground);
                    }
                }

                .messages {
                    display: flex;
                    flex-direction: column;

                    align-items: center;

                    .message {
                        display: flex;
                        flex-direction: row;

                        gap: $spacing-large;

                        align-items: center;

                        padding: $spacing;

                        background: rgba(var(--text), 0.5);

                        .message-avatar {}

                        .message-user-avatar {
                            border-radius: $border-radius-tiny;
                            border-bottom-right-radius: 0;
                        }

                        .message-content {}
                    }

                    .message:not(:first-child) {
                        border-top: 1px solid var(--middleground);
                    }

                    .message:first-child {
                        border-top-left-radius: $border-radius-small;
                        border-top-right-radius: $border-radius-small;
                    }

                    .message:last-child {
                        border-bottom-left-radius: $border-radius-small;
                        border-bottom-right-radius: $border-radius-small;
                    }

                    .message:nth-child(even) {
                        background: var(--text-inverted);
                    }
                }
            }
        }
    }
}