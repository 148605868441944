@use 'colors.scss' as *;
@use 'vars.scss' as *;

.toggle-container {
    display: flex;
    flex-direction: row;

    align-items: center;

    gap: $spacing;

    .toggle-label {
        color: var(--middleground);

        transition: $transition-slow;
    }

    .toggle-label-checked {
        color: var(--text);
    }

    .toggle {
        position: relative;

        display: inline-block;

        width: 75px;
        height: 35px;

        input {
            opacity: 0;

            width: 0;
            height: 0;
        }

        .slider {
            position: absolute;

            cursor: pointer;

            top: 0;
            left: 0;

            right: 0;
            bottom: 0;

            background-color: var(--middleground);

            transition: $transition-slow;

            border-radius: 35px;
        }

        .slider:before {
            position: absolute;

            content: '';

            height: 26px;
            width: 26px;

            left: 4px;
            bottom: 4px;

            background-color: $lightest;

            transition: $transition-slow;

            border-radius: 50%;
        }

        input:checked+.slider {
            background-color: $primary;
        }

        input:checked+.slider:before {
            transform: translateX(35px);
            box-shadow: -5px 0px 5px 2px rgba($primary-dark, 0.5);
        }
    }
}