@use 'vars.scss' as *;
@use 'colors.scss' as *;

.blog {
    display: flex;
    flex-direction: column;

    gap: $spacing-huge;

    align-items: center;

    padding-bottom: $spacing-huge;

    .blog-content {
        display: flex;
        flex-direction: column;

        gap: $spacing-huge;

        align-items: left;

        .blog-header {
            display: flex;
            flex-direction: column;

            gap: $spacing;

            .header-title {
                font-size: 30pt;
            }

            .header-description {
                color: $dark;
            }
        }

        .blog-posts {
            display: flex;
            flex-direction: column;

            gap: $spacing-huge * 2;

            align-items: center;

            .blog-post {
                display: flex;
                flex-direction: column;

                gap: $spacing-large;

                .post-image {
                    width: 100%;
                    height: 300px;

                    object-fit: cover;

                    border-radius: $border-radius;

                    cursor: pointer;

                    transition: $transition;

                    border: 1px solid $darkest;
                }

                .post-image:hover {
                    filter: brightness(0.8);
                }

                .post-details {
                    display: flex;
                    flex-direction: column;

                    gap: $spacing;

                    .post-title {
                        font-size: 20pt;
                    }

                    .post-details {
                        color: $dark;
                    }

                    .post-description {
                        color: $dark;
                    }

                    .post-date {
                        color: $dark;
                    }
                }
            }
        }
    }
}