@use 'vars.scss' as *;
@use 'colors.scss' as *;

.chat {
    display: flex;
    flex-direction: row;

    .conversations-container {
        display: flex;
        flex-direction: column;

        justify-content: space-between;
        align-items: center;

        background: var(--foreground);

        gap: $spacing-large;

        padding: $spacing-large;
        // padding-right: $spacing-large * 3;

        height: calc(100vh - 90px - $spacing-large * 3);

        // min-width: 15vw;

        .conversations-header {
            display: flex;
            flex-direction: column;

            align-items: center;

            gap: $spacing-large;
        }

        .conversations {
            height: 100%;

            overflow-y: auto;
            overflow-x: hidden;

            width: 100%;

            user-select: none;

            .conversation {
                padding: $spacing-large;

                display: flex;
                flex-direction: row;

                align-items: center;
                justify-content: space-between;

                gap: $spacing-large;

                border-radius: $border-radius;

                background: var(--foreground);

                cursor: pointer;

                transition: $transition;

                text-overflow: ellipsis;

                max-width: 100%;

                .icon {
                    color: var(--middleground);
                    transition: $transition;
                }

                .select-icon {
                    transition: $transition;
                    color: $dark;

                    opacity: 0;
                    pointer-events: none;
                }

                .icon-visible {
                    opacity: 1;
                    pointer-events: all;
                }

                .select-icon:hover {
                    color: var(--text);
                }

                .select-icon-selected {
                    color: var(--text);
                }

                .details {
                    display: flex;
                    flex-direction: column;

                    gap: $spacing;

                    width: 100%;

                    .name {
                        outline: 0;

                        // width: 0;
                        // min-width: 100%;

                        text-overflow: ellipsis;
                        overflow: hidden;
                    }

                    .name-edit {
                        outline: default;
                    }

                    .date-privacy {
                        display: flex;
                        flex-direction: row;

                        gap: $spacing;

                        align-items: center;

                        .date {
                            color: $dark;
                        }

                        .privacy-icon {
                            color: $dark;

                            transition: $transition;
                        }

                        .privacy-icon-public {
                            color: $success;
                        }

                        .privacy-icon:hover {
                            color: $lightest;
                        }
                    }

                }

                .actions {
                    display: flex;
                    flex-direction: row;

                    gap: $spacing;

                    .delete-icon {
                        opacity: 0;
                        pointer-events: none;
                    }

                    .edit-icon {
                        opacity: 0;
                        pointer-events: none;
                    }

                    .select-icon {
                        opacity: 0;
                        pointer-events: none;
                    }

                    .delete-icon:hover {
                        color: $danger;
                    }

                    .edit-icon:hover {
                        color: $dark;
                    }

                    .icon-visible {
                        opacity: 1;
                        pointer-events: all;
                    }
                }
            }

            .conversation:hover:not(.conversation-selected) {
                background: var(--middleground);

                .details {
                    .date-privacy {
                        .date {
                            color: var(--text);
                        }

                        .privacy-icon {
                            color: var(--text);
                        }
                    }
                }

                .actions {
                    .icon {
                        color: var(--text-inverted);
                    }
                }

                .select-icon {
                    color: var(--text);
                }
            }

            .conversation-selected {
                background: $primary;

                .details {
                    .date {
                        color: var(--text-inverted);
                    }
                }

                .actions {
                    .icon {
                        color: var(--text);
                    }
                }
            }
        }

        .conversations::-webkit-scrollbar {
            background: transparent;
            width: 8px;
        }

        .conversations::-webkit-scrollbar-thumb {
            background: $dark;
            border-radius: $border-radius;
        }

        .controls {
            display: flex;
            flex-direction: column;

            gap: $spacing-large;

            align-items: center;

            .error {
                color: $danger;

                text-align: center;
            }

            .select-actions {
                display: flex;
                flex-direction: row;

                gap: $spacing-large;

                .icon {
                    color: var(--middleground);
                    cursor: pointer;

                    transition: $transition;
                }

                .delete-icon {
                    color: $danger;
                }

                .icon:hover {
                    color: var(--text);
                }
            }
        }
    }

    .messaging {
        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: space-between;

        height: calc(100vh - 90px - $spacing-huge);
        width: 100%;

        .controls {
            display: flex;
            flex-direction: row;

            align-items: center;
            justify-content: center;

            gap: $spacing-large;

            margin-top: $spacing-huge;

            .message-input {
                width: 30vw;
            }
        }

        .messages {
            display: flex;
            flex-direction: column;

            width: 100%;

            overflow: auto;

            .no-messages {
                display: flex;
                flex-direction: column;

                align-items: center;

                gap: $spacing-large;

                margin-top: $spacing-huge;

                text-align: center;

                color: $dark;
            }

            .message-container {
                display: flex;
                flex-direction: column;

                margin-inline: $spacing-large;

                .message-pulldown {
                    background: var(--foreground);

                    border-top: 1px solid var(--middleground);

                    width: max-content;

                    padding: $spacing;

                    // margin-left: $spacing;

                    cursor: pointer;

                    border-bottom-left-radius: $border-radius;
                    border-bottom-right-radius: $border-radius;

                    color: var(--middleground);

                    transition: $transition;
                }

                .message-pulldown:hover {
                    color: var(--text);
                }

                .message {
                    display: flex;
                    flex-direction: row;

                    gap: $spacing-huge;

                    align-items: center;
                    justify-content: space-between;

                    padding: $spacing-large;

                    .message-icon {
                        border-radius: $border-radius-tiny;
                        border-bottom-right-radius: 0;
                    }

                    .message-content {
                        display: flex;
                        flex-direction: row;

                        gap: $spacing-huge;

                        align-items: center;

                        .details {
                            display: flex;
                            flex-direction: column;

                            gap: $spacing-small;

                            .content {
                                display: flex;
                                flex-direction: column;

                                gap: $spacing;

                                .response-loader {
                                    display: flex;
                                    flex-direction: row;

                                    gap: $spacing;

                                    align-items: center;
                                }

                                pre {
                                    background-color: $primary-darkest;
                                    font-size: 11pt;

                                    padding: $spacing-huge;

                                    border-radius: $border-radius-tiny;
                                }

                                .hljs {
                                    background-color: transparent;
                                }

                                table {
                                    background-color: var(--foreground-dark);

                                    border-radius: $border-radius-tiny;

                                    th,
                                    td {
                                        padding: 0.6rem 1rem;
                                    }

                                    thead {
                                        border-radius: $border-radius-tiny;

                                        tr {
                                            border-radius: $border-radius-tiny;

                                            background-color: $primary;
                                            text-align: left;

                                            color: var(--text);
                                        }
                                    }

                                    tbody {
                                        tr:last-of-type {
                                            border-bottom: 2px solid #64748b40;
                                        }

                                        tr:nth-of-type(odd) {
                                            background-color: #64748b20;
                                        }

                                        tr {
                                            color: var(--text-medium);
                                        }
                                    }
                                }

                                ul,
                                ol {
                                    background-color: var(--foreground-dark);

                                    padding: $spacing-huge;

                                    border-radius: $border-radius-tiny;

                                    li {
                                        margin-left: $spacing-huge;

                                        color: var(--text-medium);
                                    }

                                    li::marker {
                                        color: $primary-dark;
                                    }
                                }
                            }

                            .date {
                                color: $dark;
                            }
                        }
                    }

                    .message-actions {
                        display: flex;
                        flex-direction: row;

                        gap: $spacing-large;

                        opacity: 0.2;

                        transition: $transition;

                        .similar-result {
                            color: $primary;
                        }

                        .icon {
                            color: var(--middleground);
                            cursor: pointer;

                            transition: $transition;
                        }

                        .icon:hover {
                            color: var(--text);
                        }
                    }
                }

                .message:not(.message-similar):hover {
                    .message-actions {
                        opacity: 1;
                    }
                }

                .message-assistant {
                    background: var(--foreground);
                }
            }
        }

        .messages-empty {
            height: 100%;
            justify-content: center;
        }

        .messages::-webkit-scrollbar {
            background: transparent;
            width: 8px;
        }

        .messages::-webkit-scrollbar-thumb {
            background: $dark;
            border-radius: $border-radius;
        }
    }
}

.tooltip-container {
    position: relative;
}

.stream-responses-container {
    display: flex;
    flex-direction: row;

    gap: $spacing-large;

    align-items: center;

    .tooltip-icon {
        cursor: pointer;
    }
}