@use 'colors.scss' as *;
@use 'vars.scss' as *;

.survey {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    gap: $spacing-huge;

    padding-bottom: $spacing-large;

    .description {
        color: var(--middleground);

        text-align: center;

        width: 50%;
    }

    .sections {
        display: flex;
        flex-direction: column;

        gap: $spacing-huge;

        .section {
            display: flex;
            flex-direction: column;

            gap: $spacing-large;

            padding: $spacing-huge;
            border: 1px solid $darkest;

            border-radius: $border-radius-small;

            .section-title {
                color: $primary-dark;
            }

            .questions {
                display: flex;
                flex-direction: column;

                gap: $spacing-large;

                margin-left: $spacing-huge;

                .question {
                    .question-content {
                        display: flex;
                        flex-direction: column;

                        gap: $spacing;

                        .header {
                            .question-text {}
                        }

                        .answers {
                            display: flex;
                            flex-direction: row;

                            gap: $spacing-large;

                            select {
                                width: 200px;

                                border: 1px solid $dark;
                                border-radius: $border-radius-tiny;

                                background: transparent;

                                outline: 0;

                                padding: $spacing-small;
                            }

                            input[type='number'] {
                                width: 100px;

                                border: 1px solid $dark;
                                border-radius: $border-radius-tiny;

                                text-align: center;

                                background: transparent;

                                color: var(--text);

                                outline: 0;

                                padding: $spacing-small;
                            }

                            .answer-label {
                                display: flex;
                                flex-direction: row;

                                align-items: center;

                                gap: $spacing;
                            }

                            .answer-label {
                                input[type='radio']:checked {
                                    accent-color: $primary;
                                }
                            }

                            .multiple-choice {
                                display: grid;
                                grid-template-columns: repeat(5, 1fr);

                                gap: $spacing;

                                .personality-trait {
                                    background: transparent;

                                    border-radius: $circular-border-radius;
                                    border: 1px solid $dark;

                                    padding: $spacing $spacing-large;

                                    display: flex;
                                    flex-direction: row;

                                    gap: $spacing;

                                    align-items: center;
                                    justify-content: center;

                                    cursor: pointer;

                                    background: rgba($darkest, 0.2);

                                    transition: $transition;

                                    color: $dark;

                                    .trait {}
                                }

                                .personality-trait-selected {
                                    background: rgba($primary-dark, 0.2);
                                    border-color: $primary;

                                    color: $primary;
                                }
                            }

                            .textbox {
                                color: $lightest;
                                background: transparent;

                                width: 100%;
                                height: 100px;

                                font-family: Inter;
                                font-size: 13pt;

                                font-feature-settings: "rlig", "calt" 0;
                                font-weight: 400;

                                outline: 0;

                                padding: $spacing-small;

                                border: 1px solid $dark;

                                border-radius: $border-radius-small;
                            }
                        }
                    }
                }
            }
        }
    }
}