@use 'vars.scss' as *;
@use 'colors.scss' as *;

.about {
    display: flex;
    flex-direction: column;

    align-items: center;

    justify-content: center;

    gap: $spacing-huge * 3;

    padding: $spacing-huge;

    .section {
        display: flex;
        flex-direction: column;

        align-items: center;

        gap: $spacing-huge;

        .image-container {
            display: flex;
            flex-direction: column;

            align-items: center;

            gap: $spacing;

            .image-description {
                font-size: 13pt;

                color: var(--text-medium);
            }
        }

        .title {
            font-size: 40pt;
        }

        .text {
            max-width: 50%;
            color: var(--text-medium);

            text-align: center;
        }

        .made-for-list {
            display: flex;
            flex-direction: row;

            gap: $spacing-large;

            align-items: center;

            svg {
                color: var(--middleground);
            }

            svg:nth-child(odd) {
                color: $primary;
            }
        }
    }
}