@use 'vars.scss' as *;
@use 'colors.scss' as *;

.landing {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    height: calc(100vh - 90px);

    gap: $spacing-huge * 2;

    .page-2 {
        position: absolute;

        top: 100vh;
    }

    .sections {
        display: flex;
        flex-direction: column;

        justify-content: center;
        align-items: center;

        gap: $spacing-huge;


    }

    .buttons {
        width: 100%;

        .button {
            width: 100%;
        }
    }

    .section {
        display: flex;
        flex-direction: row;

        justify-content: center;
        align-items: center;

        .pricing-section {
            display: flex;
            flex-direction: row;

            gap: $spacing-huge;

            .pricing-card {
                display: flex;
                flex-direction: column;

                justify-content: space-between;

                gap: $spacing-large;

                border-radius: $border-radius;
                border: 1px solid var(--foreground-dark);

                padding: $spacing-huge;

                .card-content {
                    display: flex;
                    flex-direction: column;

                    gap: $spacing;

                    font-size: 18pt;


                    .header {}

                    .body {
                        display: flex;
                        flex-direction: column;

                        gap: $spacing;

                        .body-header {
                            display: flex;
                            flex-direction: row;

                            gap: $spacing;

                            align-items: center;

                            .price {
                                font-weight: 700;
                            }

                            .price-dark {
                                color: $dark;
                            }

                            .price-label {
                                font-size: 14pt;
                                color: $dark;
                            }
                        }

                        .features {
                            display: flex;
                            flex-direction: column;

                            gap: $spacing-large;

                            .feature {
                                display: flex;
                                flex-direction: row;

                                gap: $spacing;

                                align-items: center;

                                .has-feature-icon {
                                    color: $primary;
                                }

                                .feature-text {
                                    color: $primary;
                                }

                                .no-feature-icon {
                                    color: $dark;
                                }

                                .no-feature-text {
                                    color: $dark;
                                }
                            }
                        }
                    }

                }
            }

            .popular {
                box-shadow: 0 0 60px 10px rgba($primary, 0.1), inset 0 0 10px 8px rgba($primary, 0.05);
                border: 1px solid var(--middleground);
            }
        }

        @media (max-height: 1350px) {
            .pricing-section {
                display: none;
            }
        }

        .caption-container {
            position: absolute;

            bottom: $spacing-huge * 2;

            display: flex;
            flex-direction: column;

            align-items: center;

            gap: $spacing-huge;

            .captions {
                display: flex;
                flex-direction: column;

                align-items: center;

                gap: $spacing;
                font-weight: 500;

                .get-started {
                    cursor: pointer;
                }

                .caption {}

                .subcaption {
                    font-size: 11pt;
                    color: $dark;
                }
            }

            .arrow {
                cursor: pointer;
            }
        }

        .subtitle {
            font-size: 16pt;
            font-weight: 300;

            color: $dark;

            max-width: 600px;

            text-align: center;
        }

        .hero-title {
            word-wrap: break-word;

            font-size: 50pt;
            font-weight: 800;

            * {
                word-wrap: break-word;

                font-size: 50pt;
                font-weight: 800;
            }
        }
    }

    @media (max-height: 1350px) {
        .pricing-label {
            display: none;
        }
    }
}