@use 'vars.scss' as *;
@use 'colors.scss' as *;

.form-container {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    height: calc(100vh - 90px + $spacing-huge * 2);

    gap: $spacing-huge;

    margin-block: $spacing-huge;
    padding-bottom: $spacing-huge;

    .form {
        display: flex;
        flex-direction: column;

        align-items: center;

        gap: $spacing-huge;

        padding: $spacing-huge;

        border: 1px solid $dark;
        border-radius: $border-radius;

        .header {
            display: flex;
            flex-direction: column;

            align-items: center;

            gap: $spacing-large;

            .subtitle {
                color: $dark;
            }
        }

        .inputs {
            display: flex;
            flex-direction: column;

            // align-items: center;

            gap: $spacing-huge;

            .error {
                color: $danger;

                text-align: center;
                align-self: center;
            }

            .section {
                display: flex;
                flex-direction: row;

                gap: $spacing-large;

                align-items: center;
            }
        }

        .controls {
            display: flex;
            flex-direction: row;

            align-items: center;

            gap: $spacing-huge;

            .buttons {
                display: flex;
                flex-direction: row;

                align-items: center;

                gap: $spacing;
            }
        }
    }
}