@use 'vars.scss' as *;
@use 'colors.scss' as *;

.avatar-editor {
    display: flex;
    flex-direction: column;

    gap: $spacing-huge;

    align-items: center;

    .avatar {
        .avatar-image {
            width: 100%;
            height: 100%;

            object-fit: cover;

            border-radius: 50%;
        }
    }

    .previous-avatars {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);

        gap: $spacing;

        .previous-avatar {
            .previous-avatar-image {
                width: 100%;
                height: 100%;

                object-fit: cover;
            }
        }
    }
}