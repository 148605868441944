$spacing-huge: 25px;
$spacing-large: 15px;
$spacing: 10px;
$spacing-small: 5px;

$border-radius-large: 25px;
$border-radius: 15px;
$border-radius-small: 8px;
$border-radius-tiny: 4px;
$circular-border-radius: 9999999px; // Lol

$transition: 0.3s;
$transition-slow: 0.4s;