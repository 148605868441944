@use 'vars.scss' as *;
@use 'colors.scss' as *;

.tooltip {
    padding: $spacing;

    border: 1px solid $dark;
    border-radius: $border-radius;

    position: absolute;

    top: 0;
    left: 0;

    z-index: 1;

    background: $darkest;
    color: $lightest;

    opacity: 0;

    pointer-events: none;

    transition: $transition;

    margin-left: calc(100% + $spacing);

    width: max-content;
    max-width: 220px;

    z-index: 9999;

    .message {
        overflow: auto;
    }
}

.tooltip-show {
    opacity: 1;
}

.tooltip-right-anchor {
    margin-left: 0;
    margin-right: calc(100% + $spacing);

    right: 0;

    transform: translate(-100%, -50%);
}