$primary-lightest: #EEECFE;
$primary-light: #AC9EFA;
$primary: #6F57F7;
$primary-dark: #4729F5;
$primary-darkest: #030113;

$success: #88B7B5;

$danger: #EE4266;

$warning: #E58F28;

$lightest: #FFFFFF;
$medium: #8192B1;
$dark: #4A4A4A;
$darkest: #11151C;