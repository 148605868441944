@use 'vars.scss' as *;
@use 'colors.scss' as *;

.label {
    border: 1px solid $primary;
    border-radius: $border-radius-large;

    padding: $spacing-small $spacing;
}

.label-icon {
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    gap: $spacing;

    i {
        font-size: 12pt;
    }
}