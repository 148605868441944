@use 'vars.scss' as *;
@use 'colors.scss' as *;

.pricing {
    display: flex;
    flex-direction: column;

    gap: $spacing-huge;

    align-items: center;
    justify-content: center;

    height: calc(100vh - 90px + $spacing-huge * 4);

    margin-block: $spacing-huge;
    padding-bottom: $spacing-huge;

    .subtitle {
        color: $dark;
    }

    .pricing-content {
        display: flex;
        flex-direction: row;

        gap: $spacing-huge;

        .pricing-card {
            display: flex;
            flex-direction: column;

            justify-content: space-between;

            gap: $spacing-large;

            border-radius: $border-radius;
            border: 1px solid var(--foreground-dark);

            padding: $spacing-huge;

            .card-content {
                display: flex;
                flex-direction: column;

                gap: $spacing;

                .header {}

                .body {
                    display: flex;
                    flex-direction: column;

                    gap: $spacing;

                    .body-header {
                        display: flex;
                        flex-direction: row;

                        gap: $spacing;

                        align-items: center;

                        .price {
                            font-weight: 700;
                        }

                        .price-dark {
                            color: $dark;
                        }

                        .price-label {
                            font-size: 14pt;
                            color: $dark;
                        }
                    }

                    .features {
                        display: flex;
                        flex-direction: column;

                        gap: $spacing-large;

                        .feature {
                            display: flex;
                            flex-direction: row;

                            gap: $spacing;

                            align-items: center;

                            .has-feature-icon {
                                color: $primary;
                            }

                            .feature-text {
                                color: $primary;
                            }

                            .no-feature-icon {
                                color: $dark;
                            }

                            .no-feature-text {
                                color: $dark;
                            }
                        }
                    }
                }
            }

            @media (min-width: 1000px) {
                font-size: 18pt;
            }
        }

        .popular {
            box-shadow: 0 0 60px 10px rgba($primary, 0.1), inset 0 0 10px 8px rgba($primary, 0.05);
            border: 1px solid var(--middleground);
        }
    }
}