@use 'vars.scss' as *;
@use 'colors.scss' as *;

.input-container {
    display: flex;
    flex-direction: column;

    .input {
        border-radius: $border-radius-small;

        padding: $spacing-large $spacing;

        outline: 0;

        color: var(--text);

        transition: $transition;
    }

    .input-default {
        border: 1px solid $dark;
        background: transparent;

        background: transparent;
    }

    .input-error {
        color: $danger;
        border-color: $danger;
    }

    .input-label {
        color: var(--text);
    }
}

.input-has-label {
    gap: $spacing;
}