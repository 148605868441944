@use 'vars.scss' as *;
@use 'colors.scss' as *;

.settings-container {
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    backdrop-filter: blur(5px);

    background: rgba($primary-darkest, 0.8);

    position: absolute;

    z-index: 9999999;

    .settings {
        display: flex;
        flex-direction: column;

        background: var(--foreground);

        padding: $spacing-huge;

        border-radius: $border-radius;

        gap: $spacing-huge;

        border: 1px solid var(--middleground);

        .header {
            display: flex;
            flex-direction: row;

            justify-content: space-between;

            align-items: center;

            .close-icon {
                color: $danger;

                cursor: pointer;
            }
        }

        .section {
            display: flex;
            flex-direction: column;

            gap: $spacing-large;
        }
    }
}