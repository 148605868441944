@use 'vars.scss' as *;
@use 'colors.scss' as *;

.navbar-container {
    width: 100%;

    position: sticky;


    top: 0;
    left: 0;

    background: var(--background);

    z-index: 9999;

    .navbar {
        display: flex;
        flex-direction: row;

        justify-content: space-between;
        align-items: center;

        padding: $spacing-large $spacing-huge;

        position: relative;

        height: 45px;

        margin-inline: 25%;
        margin-bottom: $spacing-huge;

        .section {
            position: relative;

            .logo {
                display: flex;
                flex-direction: row;

                gap: $spacing-large;

                align-items: center;

                .logo-img {
                    height: 40px;
                }
            }

            .buttons {
                display: flex;
                flex-direction: row;

                gap: $spacing-large;

                align-items: center;

                .theme-icon {
                    color: var(--middleground);
                    cursor: pointer;
                }
            }

            .navigation {
                display: flex;
                flex-direction: row;

                gap: $spacing-large;

                align-items: center;

                padding: $spacing-large $spacing-huge;

                border-radius: $circular-border-radius;
                border: 1px solid $darkest;

                transition: $transition;

                position: absolute;

                top: 50%;
                left: calc(50% + $spacing-large * 2);

                transform: translate(-50%, -50%);

                .link {
                    transition: $transition;

                    cursor: pointer;

                    color: var(--middleground);
                }

                .link-active {
                    color: $primary;
                }

                .link:hover {
                    color: $primary;
                }
            }

            .navigation:hover {
                border-color: $primary-dark;
            }
        }
    }

    .navbar::after {
        content: '';

        position: absolute;

        bottom: 0;
        left: 0;

        width: 100%;
        height: 1px;

        background: $darkest;

        background: linear-gradient(to right, transparent, $darkest, $darkest, transparent);
    }

    @media (max-width: 1550px) {
        .navbar {
            margin-inline: 0;
        }
    }
}