@use 'vars.scss' as *;
@use 'colors.scss' as *;

.horizontal-selector {
    display: flex;
    flex-direction: row;

    align-items: center;

    .item {
        background: transparent;

        border: 1px solid $dark;

        color: $dark;

        padding: $spacing;

        cursor: pointer;

        transition: $transition;
    }

    .item:first-child {
        border-top-left-radius: $border-radius-small;
        border-bottom-left-radius: $border-radius-small;
    }

    .item:last-child {
        border-top-right-radius: $border-radius-small;
        border-bottom-right-radius: $border-radius-small;
    }

    .item-selected {
        background: rgba($primary-dark, 0.2);
        border-color: $primary;

        color: $primary;
    }
}